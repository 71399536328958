"use client";

import "./page.css"; // Import the layout-specific CSS

import { useEffect } from "react";
import { Thread } from "@assistant-ui/react";
import { makeMarkdownText } from "@assistant-ui/react-markdown";
import { DisplayDataTool } from "@/components/tools/DisplayDataTool";

const MarkdownText = makeMarkdownText();
 
const getOrCreateUserId = async () => {
  let userId = localStorage.getItem("coverboy_user_id");
  if (!userId) {
    // Generate unique identifier if it doesn't already exist
    userId = crypto.randomUUID();
    localStorage.setItem("coverboy_user_id", userId);
  }

  const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
  // Send user info to backend
  try {
    await fetch(`${baseUrl}/track_user`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId }),
    });
  } catch (error) {
    console.error("Error tracking user:", error);
  }
};

export default function Home() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // You can use localStorage here
      getOrCreateUserId();
    }
  }, []);
  

  return (
    <div className="flex h-full flex-col">
      <Thread
        welcome={{
          suggestions: [
            {
              prompt: "What are Jayson Tatum's assist stats when Jaylen Brown is out?",
            },
            {
              prompt: "Saquon Barkley 2H rushing stats when the Eagles are leading by 7 or more points and Jalen Hurts is starting at QB",
            },
          ],
        }}
        assistantMessage={{ components: { Text: MarkdownText } }}
        tools={[DisplayDataTool]}
      />
    </div>
  );
}