"use client";

import { makeAssistantToolUI } from "@assistant-ui/react";
import { Button, Table, TableColumnType, Switch } from "antd";
import { SortOrder } from "antd/es/table/interface";
import "./DisplayDataTool.css";
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import annotationPlugin from "chartjs-plugin-annotation";
import PlotButton from "./PlotButton";
import PlotDisplay from "./PlotDisplay";

import { useState, useEffect } from "react";

Chart.register(...registerables, annotationPlugin);

// I don't know why we need this. Placeholder for now.
type DisplayDataToolArgs = {
  placeholder: string;
};

// Given a string like "o25 (-100)",
// return the odds line, or 25 in this case.
const extractNumberFromOdds = (value) => {
  const match = value.match(/[a-zA-Z]\s*(\d+(\.\d+)?)/); // Match number (integer or decimal) after a letter
  return match ? parseFloat(match[1]) : null;
};

// Define the structure of a table row
export type TableRow = {
  [key: string]: string | number | null; // Adjust this type based on your actual data
};

export const DisplayDataTool = makeAssistantToolUI<
  DisplayDataToolArgs,
  string
>({
  toolName: "display_data",
  render: function DisplayDataUi({ result, argsText }) {
    console.log(argsText);
    const [selectedPlot, setSelectedPlot] = useState<string>();
    const [columns, setColumns] = useState<TableColumnType<TableRow>[]>([]);
    const [rows, setRows] = useState<TableRow[]>([]);
    const [isBettingInfoEnabled, setIsBettingInfoEnabled] = useState<boolean>(true);

    const table: TableRow[] = result ? JSON.parse(result as string) : [];

    // Extracted render function
    const renderCell = (value: string, record: TableRow, key: string, keys: string[]) => {
      const lowerKey = key.toLowerCase();
      if (key === "game info") {
        return <div>{value}</div>;
      }

      const isLineColumn = lowerKey.includes("line");
      if (!isLineColumn) {
        // Eventually replace this with a mapping.
        const lineColumnKey = keys.find((k) => {
          if (lowerKey === 'over/under') {
            return k.toLowerCase() === 'total points (pre-game) (line)';
          }

          // 3pm/3pa -> 3pm (line)
          if (lowerKey === '3pm/3pa') {
            return k.toLowerCase() === "3pm (line)";
          }

          const lowerK = k.toLowerCase();
          return lowerK.includes("line") && lowerK.startsWith(lowerKey);
        });

        if (lineColumnKey) {
          const lineValue = record[lineColumnKey];
          let line;
          if (typeof lineValue === "string") {
            line = extractNumberFromOdds(lineValue);
          } else if (typeof lineValue === "number") {
            line = lineValue;
          }

          const red = "#ffb3b3";
          const green = "#ccffcc";
          let backgroundColor;

          if (value == 'under' || value == 'over') {
            backgroundColor = value == 'under' ? red : green;
          } else if ((lowerKey === '3pm/3pa' || typeof value === "number") && typeof line === "number") {
            // Create a copy of value for comparing against the betting line.
            let valueForComparison: number;
            
            // Parse out 3pm to calculate the difference between 3pm and 3pm (line).
            if (lowerKey === '3pm/3pa') {
              valueForComparison = Number(value.split('/')[0]);
            } else {
              valueForComparison = Number(value);
            }

            const difference = valueForComparison - line;
            backgroundColor = difference > 0 ? green : red;
          }

          return (
            <div style={{ backgroundColor }}>
              {value}
            </div>
          );
        }
      }

      return <div>{value}</div>;
    };

    useEffect(() => {
      const keys = isBettingInfoEnabled 
        ? Object.keys(table[0]) 
        : Object.keys(table[0]).filter((key) => !key.toLowerCase().includes("(line)"));
 
      // Update columns and rows when toggleState changes
      const newColumns: TableColumnType<TableRow>[] = 
      
      
      keys.map((key) => ({
        title: key.toUpperCase(),
        align: 'center',
        dataIndex: key,
        key: key,
        sorter: (a, b) => {
          if (key === "Game Info" && typeof(a[key]) === 'string' && typeof(b[key]) === 'string') {
            const dateA = new Date(a[key].split(" ")[0]).getTime(); // Extract date part and convert to Date
            const dateB = new Date(b[key].split(" ")[0]).getTime();
            return dateA - dateB;
          }
          if (typeof a[key] === "number" && typeof b[key] === "number") {
            return a[key] - b[key];
          }
          if (typeof a[key] === "string" && typeof b[key] === "string") {
            return a[key].localeCompare(b[key]);
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"] as SortOrder[],
        fixed: key === "Game Info" ? "left" : undefined,
        className: (key === "Game Info" || key === 'Date') ? "fixed-column-left" : undefined,
        width: key === "Game Info" ? 150 : undefined,
        onFilter: (value, record) => record[key] === value,
        render: (value, record) => renderCell(value, record, key, keys),
      }));

      setColumns(newColumns);
      setRows(table.map((row, index) => ({ id: index, ...row })));
    }, [isBettingInfoEnabled, result]); // Runs when toggleState or result changes

    const plottableKeys = Object.keys(table[0]).flatMap(item => {
      if (item === 'Game Info') return []; // Remove 'Game Info'
      if (item.includes('(Line)')) return []; // Remove '(Line)' items
      if (item === 'Cmp/Att') return ['Completions', 'Pass Atts']; // Split 'Cmp/Att'
      if (item === 'Pos') return []; // Remove 'Pos'
      if (item === 'FGM/FGA') return ['FGM', 'FGA']; // Split 'Fgm/Fga'
      if (item === '3PM/3PA') return ['3PM', '3PA']; // Split '3pm/3pa'
      if (item === 'Over/Under') return []; // Remove 'Over/Under'
      if (item === 'Player') return []; // Remove 'Player'. Cannot plot player name.
      return item;
  });

  const handlePlotSelect = (plotSelection: string) => {
    setSelectedPlot(plotSelection);
  };


  const onBettingToggleChange = (bettingInfoToggle: boolean) => {
    setIsBettingInfoEnabled(bettingInfoToggle);
  };
  
  const handleDownloadExcel = async () => {
    if (!rows.length) return;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headers = Object.keys(rows[0]);
    worksheet.addRow(headers); // Add headers

    rows.forEach((row, rowIndex) => {
        const rowData = headers.map(header => row[header]);
        const newRow = worksheet.addRow(rowData);

        const firstColumnValue = String(rows[rowIndex]["Game Info"] || "").toUpperCase();
        if (firstColumnValue.includes("AVERAGE")) return;

        headers.forEach((key, colIndex) => {
            const lowerKey = key.toLowerCase();
            const isLineColumn = lowerKey.includes("line");

            if (!isLineColumn) {
                const lineColumnKey = Object.keys(row).find(k => {
                  const lineKey = k.toLowerCase();
                  if (lowerKey === "3pm/3pa") {
                    return lineKey === "3pm (line)";
                  }
                  return lineKey.includes("line") && lineKey.startsWith(lowerKey);
                });

                if (lineColumnKey) {
                  let statValue: number;
                  if (lowerKey === "3pm/3pa" && typeof row[key] === "string")  {
                    statValue = Number(row[key].split('/')[0]);
                  } else {
                    statValue = Number(row[key]);
                  }
                  
                  const lineValueRaw = row[lineColumnKey];

                  let line;
                  if (typeof lineValueRaw === "string") {
                      if (lineValueRaw === "N/A") return;
                      line = extractNumberFromOdds(lineValueRaw);
                  } else if (typeof lineValueRaw === "number") {
                      line = lineValueRaw;
                  }

                  const difference = statValue - line;

                  if (difference !== 0) {
                      const cell = newRow.getCell(colIndex + 1);
                      if (difference > 0) {
                          cell.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'CCFFCC' } // Green
                          };
                      } else if (difference < 0) {
                          cell.fill = {
                              type: 'pattern',
                              pattern: 'solid',
                              fgColor: { argb: 'FFB3B3' } // Red
                          };
                      }
                  }
                }
            }
        });
    });
    
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'coverboy_table.xlsx');
  };
    
    return (
      <>
        <div className="betting-info-toggle">
          <Switch onChange={onBettingToggleChange}
          defaultChecked
          checkedChildren="Betting On"
          unCheckedChildren="Betting Off"
          />
        </div>
        <div className="data-grid">
          <Table
            className="ant-table-wrapper"
            columns={columns}
            dataSource={rows}
            rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
            pagination={{ position: ['bottomCenter'] }}
            scroll={{ x: 'max-content' }}
          />
        </div>
        <div className="download-excel">
          <Button onClick={handleDownloadExcel}>
            Download table
          </Button>
        </div>
        <PlotButton menuItems={plottableKeys} onPlotSelect={handlePlotSelect}></PlotButton>
        {selectedPlot && <PlotDisplay table={table} selectedPlot={selectedPlot} isBettingInfoEnabled={isBettingInfoEnabled} />}
      </>

    );
  },
});

