import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import { TableRow } from "./DisplayDataTool"; 

type PlotDisplayProps = {
  table: TableRow[];
  selectedPlot: string;
  isBettingInfoEnabled: boolean;
};

interface Dataset {
    id: number;
    label: string;
    data: number[];
    backgroundColor?: string[];
    grouped?: boolean;
    categoryPercentage?: number;
  }
  
interface PlotData {
    labels: string[];
    datasets: Dataset[];
  }
  

// Given a table of [{colName: val, ... }, {...}, ...]
// Return array of all data in that column.
const extractColumnData = (table, columnName, isOdds=false) => {
    if (isOdds) {
      return table.map(row => row[columnName]).map(val => extractNumberFromOdds(val));
    }
  
    if (columnName === 'Completions' || columnName === 'Pass Atts') {
      // Need to get data from CMP/ATT column.
      const cmpAttData = table.map(row => row['Cmp/Att']);
  
      if (columnName === 'Completions') {
        const completions = cmpAttData.map(entry => Number(entry.split('/')[0]));
        return completions;
      } else {
        const attempts = cmpAttData.map(entry => Number(entry.split('/')[1]));
        return attempts;
      }
    }

    if (columnName === '3PM' || columnName === '3PA') {
      // Need to get data from 3PM/3PA column.
      const threePointAttData = table.map(row => row['3PM/3PA']);

      if (columnName === '3PM') {
        const threePointersMade = threePointAttData.map(entry => Number(entry.split('/')[0]));
        return threePointersMade;
      } else {
        const threePointersAttempted = threePointAttData.map(entry => Number(entry.split('/')[1]));
        return threePointersAttempted;
      }
    }

    if (columnName === 'FGM' || columnName === 'FGA') {
      // Need to get data from FGM/FGA column.
      const fgAttData = table.map(row => row['FGM/FGA']);

      if (columnName === 'FGM') {
        const fgMade = fgAttData.map(entry => Number(entry.split('/')[0]));
        return fgMade;
      } else {
        const fgAtt = fgAttData.map(entry => Number(entry.split('/')[1]));
        return fgAtt;
      }
    }

    return table.map(row => row[columnName]);
}
  
  // Given a string like "o25 (-100)",
  // return the odds line, or 25 in this case.
const extractNumberFromOdds = (value) => {
    const match = value.match(/[a-zA-Z]\s*(\d+(\.\d+)?)/); // Match number (integer or decimal) after a letter
    return match ? parseFloat(match[1]) : null;
};

const PlotDisplay = ({ table, selectedPlot, isBettingInfoEnabled }: PlotDisplayProps) => {
  const [plotData, setPlotData] = useState<PlotData | null>(null);
  const [plotOptions, setPlotOptions] = useState<ChartOptions>({});

  useEffect(() => {
    if (!selectedPlot || table.length === 0) return;

    // Filter out rows where 'game_information' is 'AVERAGE'
    const filteredTable = table.filter(row => row['Game Info'] !== 'AVERAGE' && row['Game Info'] !== '2024-25 SEASON AVERAGE');

    const keys = Object.keys(filteredTable[0]);
    const xAxisLabels = extractColumnData(filteredTable, "Game Info");
    const data = extractColumnData(filteredTable, selectedPlot);

    const lineLabel = selectedPlot + " (Line)";
    const hasLineData = keys.includes(lineLabel) && isBettingInfoEnabled;
    const lineData = hasLineData ? extractColumnData(filteredTable, lineLabel, true) : [];

    const red = "#ffb3b3";
    const green = "#ccffcc";
    const blue = "#2186c4";

    const minBarHeight = 0.05; // Smallest bar value to ensure visibility
    const adjustedData = data.map((d) => (d === 0 ? minBarHeight : d));

    const colors = hasLineData 
    ? data.map((d, i) => 
        lineData[i] === 'N/A' || lineData[i] === 'None' || lineData[i] == null 
          ? blue 
          : d > lineData[i] 
            ? green 
            : red
      )
    : data.map(() => blue);

    const options: ChartOptions = {
      responsive: true,
      plugins: {
        legend: {
          labels: {
            generateLabels: () => {
              return hasLineData
                ? [
                    { text: "Over", fillStyle: green, strokeStyle: green, lineWidth: 1 },
                    { text: "Under", fillStyle: red, strokeStyle: red, lineWidth: 1 },
                  ]
                : [{ text: selectedPlot, fillStyle: "#2186c4", lineWidth: 1 }];
            },
          },
        },
      },
    };

    setPlotOptions(options);
    setPlotData({
      labels: xAxisLabels,
      datasets: [
        {
          id: 1,
          label: selectedPlot,
          data: adjustedData,
          backgroundColor: colors,
          grouped: false,
          categoryPercentage: 0.2,
        },
        {
          id: 2,
          label: lineLabel,
          data: lineData,
          categoryPercentage: 0.4,
        },
      ],
    });
  }, [table, selectedPlot]);

  return plotData ? 
  <Bar 
    data={plotData} 
    // @ts-expect-error Not sure why there is a type mismatch. Look into it later.
    options={plotOptions} /> 
  : null;
};

export default PlotDisplay;
