import React, { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const PlotButton = ({ menuItems, onPlotSelect }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (item) => {
    // Pass the selected plot item to the parent component
    onPlotSelect(item);
  };

  const items = menuItems.map((item, index) => ({
    label: item,
    key: `item-${index}`,
    onClick: () => handleClick(item),
  }));

  return (
    <Dropdown
      menu={{items}}
      onOpenChange={(flag) => setVisible(flag)}
      open={visible}
    >
      <Button>
        Plot <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default PlotButton;